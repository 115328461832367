// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-bastian-nolte-3-ps-tsx": () => import("./../../../src/pages/bastian-nolte-3ps.tsx" /* webpackChunkName: "component---src-pages-bastian-nolte-3-ps-tsx" */),
  "component---src-pages-en-faq-tsx": () => import("./../../../src/pages/en/faq.tsx" /* webpackChunkName: "component---src-pages-en-faq-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-edu-template-tsx": () => import("./../../../src/templates/eduTemplate.tsx" /* webpackChunkName: "component---src-templates-edu-template-tsx" */)
}

