import 'styles/main.scss'

require("typeface-muli");
require("typeface-montserrat");
// Code Highlighting Theme
require("prismjs/themes/prism-tomorrow.css")

// Reload site if service worker needs an update.
export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
